import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";

// Importing images
import TiffinPros from "../../../assets/images/TiffinPros.png";
import FantastycIndia from "../../../assets/images/FantastycIndia.png";
import KaurSKitchen from "../../../assets/images/Kaur_sKitchen.png";
import TulsiIndianCuisine from "../../../assets/images/TulsiIndianCuisine.png";
import LalsKitchen from "../../../assets/images/Lal_sKitchen.png";

const SellerImageSlider = () => {
  // Array of images
  const images = [
    TiffinPros,
    FantastycIndia,
    KaurSKitchen,
    TulsiIndianCuisine,
    LalsKitchen,
  ];

  return (
    <div style={{ backgroundColor: "#FFF9F5", padding: "48px 0" }}>
      <Container>
        <h4 className="text-center mb-4 ff-poppins fw-bold">
          Sellers in Canada are growing with TiffinStash
        </h4>

        {/* Desktop Grid Layout */}
        <Row className="d-none d-md-flex justify-content-center text-center">
          {images.map((src, index) => (
            <Col
              key={index}
              md={2}
              className="d-flex justify-content-center"
              style={{ gap: "78px" }}
            >
              <img
                src={src}
                alt={`Seller ${index + 1}`}
                style={{ width: "70%", height: "auto", borderRadius: "50%" }}
              />
            </Col>
          ))}
        </Row>

        {/* Mobile Carousel */}
        <div className="d-md-none text-center">
          <Carousel interval={3000} indicators>
            {images.map((src, index) => (
              <Carousel.Item key={index}>
                <div className="d-flex justify-content-center">
                  <img
                    src={src}
                    alt={`Seller ${index + 1}`}
                    style={{
                      width: "150px",
                      height: "auto",
                      borderRadius: "50%",
                    }}
                  />
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </Container>
    </div>
  );
};

export default SellerImageSlider;
