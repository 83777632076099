import React from "react";
import { Container, Accordion, Card } from "react-bootstrap";

const FAQ = () => {
  return (
    <div className="py-5 bg-white">
      <Container>
        <h4 className="fw-bold text-center mb-0 ff-poppins fs_24 color-dark-grey">
          Frequently asked questions
        </h4>

        <Accordion className="mt-5" defaultActiveKey="0">
          <Card className="border-0 mb-4 pb-3">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <h5 className="font-medium color-dark-grey ff-poppins pb-3">
                  How long does it take to become a TiffinStash partner?
                </h5>
              </Accordion.Header>
              <Accordion.Body className="ff-poppins color-light-black fs_14 pt-0">
                Becoming a TiffinStash partner typically takes between 2 to 10
                business days, depending on your specific requirements and the
                completion of necessary processes.
              </Accordion.Body>
            </Accordion.Item>
          </Card>

          <Card className="border-0 mb-4 pb-3">
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <h5 className="font-medium color-dark-grey ff-poppins pb-3">
                  Where does TiffinStash deliver within Canada?
                </h5>
              </Accordion.Header>
              <Accordion.Body className="ff-poppins color-light-black fs_14 pt-0">
                TiffinStash delivers to the following areas within Canada:
                <ul>
                  <li>
                    <strong>Toronto:</strong> Etobicoke, North York, East York,
                    Scarborough, Downtown Toronto, Midtown Toronto
                  </li>
                  <li>
                    <strong>Durham Region:</strong> Ajax, Oshawa, Pickering,
                    Whitby
                  </li>
                  <li>
                    <strong>Halton Region:</strong> Oakville
                  </li>
                  <li>
                    <strong>Peel Region:</strong> Brampton, Mississauga
                  </li>
                  <li>
                    <strong>York Region:</strong> Markham (limited area),
                    Richmond Hill (limited area), Vaughan
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
          </Card>

          <Card className="border-0 mb-4 pb-3">
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                <h5 className="font-medium color-dark-grey ff-poppins pb-3">
                  What types of support do TiffinStash seller partners receive?
                </h5>
              </Accordion.Header>
              <Accordion.Body className="ff-poppins color-light-black fs_14 pt-0">
                TiffinStash seller partners receive various types of support to
                enhance their experience:
                <ul>
                  <li>
                    We provide thorough onboarding and setup assistance to help
                    you get started smoothly.
                  </li>
                  <li>
                    You'll have access to efficient tools for managing orders
                    effectively.
                  </li>
                  <li>
                    We ensure seamless coordination for deliveries to streamline
                    your operations.
                  </li>
                  <li>
                    Additionally, our team offers marketing and promotional
                    support to help boost your visibility and sales.
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
          </Card>

          <Card className="border-0 mb-2">
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                <h5 className="font-medium color-dark-grey ff-poppins pb-3">
                  Where can I go for more questions?
                </h5>
              </Accordion.Header>
              <Accordion.Body className="ff-poppins color-light-black fs_14 pt-0">
                For further inquiries, you can contact us via email at{" "}
                <a href="mailto:seller@tiffinstash.com">
                  seller@tiffinstash.com
                </a>{" "}
                or reach out to us on WhatsApp at +1 (647) 507-8274.
              </Accordion.Body>
            </Accordion.Item>
          </Card>
        </Accordion>
      </Container>
    </div>
  );
};

export default FAQ;
