import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button, Modal } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";

import logo from "../../../assets/logo/logo.svg";
import tiffinlogo from "../../../assets/logo/tiffin-logo.png";
import dashbgseller from "../../../assets/images/dashbg-seller.png";
import formSubmittedPopupimg from "../../../assets/images/formSubmittedPopupimg.png"; // Import the modal image

const SellerLandingHeader = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isFading, setIsFading] = useState(false);

  const textAndImages = [
    "Grow your tiffin business online",
    "Serve tiffins beyond your area",
    "Unlock more profits and increase revenue",
  ];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsFading(true);

      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % textAndImages.length);
        setIsFading(false);
      }, 500);
    }, 3500);

    return () => clearInterval(intervalId);
  }, []);

  const businessTypes = [
    "I run a tiffin service from a commercial kitchen",
    "I run a tiffin service from home",
    "I want to start tiffin service from a commercial kitchen",
    "I want to start tiffin service from home",
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    setOpenModal(true);
  };

  const handleCloseModal = () => setOpenModal(false);

  // Calculate left position for the progress bar
  const getProgressLeft = () => {
    if (currentIndex === 0) return "0%";
    if (currentIndex === 1) return "33.33%";
    if (currentIndex === 2) return "66.67%";
    return "0%";
  };

  return (
    <div
      className="header-height"
      style={{ display: "flex", flexDirection: "column" }}
    >
      {/* Navbar */}
      <div className="bg-white">
        <Container className="py-3">
          <img src={logo} width={180} height={70} alt="TiffinStash Logo" />
        </Container>
      </div>

      {/* Hero Section */}
      <div
        className="py-5"
        style={{
          flex: 1,
          backgroundImage: `url(${dashbgseller})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "relative",
        }}
      >
        <div
          className="overlay"
          style={{
            backgroundColor: "#000000B2",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
        ></div>
        <Container
          className="d-flex align-items-center justify-content-center position-relative"
          style={{ height: "100%", zIndex: 2 }}
        >
          <Row className="align-items-center justify-content-between">
            <Col xl={7}>
              <img src={tiffinlogo} width={24} alt="Tiffin Logo" />
              <h6 className="text-white ff-poppins mt-2 pt-1">
                PARTNER WITH TIFFINSTASH
              </h6>
              <h2
                className={`text-white ff-poppins fs_53 ${
                  isFading ? "fade-out" : "fade-in"
                }`}
                style={{ opacity: isFading ? 0 : 1 }}
              >
                {textAndImages[currentIndex]}
              </h2>
              <div className="progress-bar-container">
                <div
                  className="progress-bar"
                  style={{ left: getProgressLeft() }}
                ></div>
              </div>
            </Col>

            <Col className="mt-4 mt-xl-0" xl={5}>
              <Form
                onSubmit={handleSubmit}
                className="p-4 bg-white rounded shadow-sm z-2 w-100 d-flex flex-column gap-3"
              >
                <h4 className="ff-poppins fw-bold fs_21">
                  Join us, list your tiffin service, and start selling.
                </h4>

                <Form.Group controlId="businessName">
                  <Form.Control
                    type="text"
                    placeholder="Business Name"
                    required
                    className="ff-poppins form-input ps-4"
                  />
                </Form.Group>

                <Row className="my-sm-3 flex-sm-row gap-3 gap-sm-0 flex-column">
                  <Col sm={6}>
                    <Form.Group controlId="email">
                      <Form.Control
                        type="email"
                        placeholder="Email ID"
                        required
                        className="ff-poppins form-input ps-4"
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    <Form.Group controlId="phoneNumber">
                      <PhoneInput
                        country={"us"}
                        value={phoneNumber}
                        onChange={setPhoneNumber}
                        inputStyle={{ width: "100%" }}
                        placeholder="Phone Number"
                        required
                        containerClass="ff-poppins form-input"
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Group controlId="businessType">
                  <Form.Control
                    as="select"
                    required
                    className="ff-poppins form-input ps-4 drop-down-option"
                  >
                    <option value="" disabled selected>
                      Select your business type
                    </option>
                    {businessTypes.map((type) => (
                      <option
                        key={type}
                        value={type}
                        className="ff-poppins form-input"
                      >
                        {type}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>

                <Button
                  variant="primary"
                  type="submit"
                  block
                  style={{ backgroundColor: "#DE5200", border: "none" }}
                  className="ff-poppins w-100 my-2 get-started-button"
                >
                  Get Started
                </Button>
                <p className="text-muted text-center mt-2 ff-poppins mb-0">
                  By clicking "Get Started," I agree to receive marketing
                  communications via electronic means from TiffinStash.
                </p>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Modal for form submission */}
      <Modal show={openModal} onHide={handleCloseModal} centered>
        <Modal.Header
          style={{ background: "#F8F8F8", border: "1px solid #EFF1FE" }}
          closeButton
        ></Modal.Header>
        <Modal.Body className="text-center">
          <img
            src={formSubmittedPopupimg}
            alt="Form Submitted"
            style={{ maxWidth: "64px" }}
          />
          <p
            style={{ color: "#0D0E15" }}
            className="ff-poppins mb-0 fw-semibold mt-2"
          >
            Application Received!
          </p>
          <p className="ff-poppins mb-0 color-grey mt-3">
            Thank you for submitting the form. Our team will get in touch with
            you soon.
          </p>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SellerLandingHeader;
