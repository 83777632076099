import React from "react";
import { Container, Row, Col, ListGroup, Button, Form } from "react-bootstrap";
import logoWhite from "../../../assets/images/logo-white.png";
import googlePlay from "../../../assets/images/google.png";
import appStore from "../../../assets/images/apple.png";
import facebookIcon from "../../../assets/images/facebook.png";
import instagramIcon from "../../../assets/images/insta.png";
import youtubeIcon from "../../../assets/images/youtube.png";
import linkedInIcon from "../../../assets/images/linked.png";
import whatsappIcon from "../../../assets/images/whatapp.png";
import arrowRight from "../../../assets/images/arrow-right3.svg";

const SellerLandingFooter = () => {
  const deliveryAreasPart1 = [
    "Tiffin service in Brampton",
    "Tiffin service in Toronto",
    "Tiffin service in Etobicoke",
    "Tiffin service in North York",
    "Tiffin service in Thornhill",
    "Tiffin service in Scarborough",
    "Tiffin service in East York",
    "Tiffin service in Markham",
  ];

  const deliveryAreasPart2 = [
    "Tiffin service in Richmond Hill",
    "Tiffin service in Vaughan",
    "Tiffin service in Woodbridge",
    "Tiffin service in Ajax",
    "Tiffin service in Pickering",
    "Tiffin service in Whitby",
    "Tiffin service in Oshawa",
    "Tiffin service in Oakville",
  ];

  return (
    <div className="bg-color-dark-grey text-white py-5">
      <Container>
        <Row className="mb-4">
          {/* Left Column */}
          <Col xs={12} xl={4} className="pe-md-4">
            <div className="d-flex flex-column align-items-start">
              <img
                src={logoWhite}
                alt="TiffinStash Logo"
                style={{ width: "150px", height: "auto" }}
              />
              <p className="mt-4 ff-poppins" style={{ fontSize: "14px" }}>
                TiffinStash is an e-commerce marketplace platform for tiffin
                services in Canada. Customers can easily order a single tiffin
                or subscribe to weekly or monthly tiffin plans from curated
                tiffin sellers on the platform. TiffinStash ensures everyday
                delivery of freshly prepared tiffins. Sellers can expand their
                reach and grow their business by listing their tiffin services
                on the marketplace.
              </p>
            </div>
          </Col>

          {/* Right Column */}
          <Col xs={12} xl={8} className="ps-xl-5 mt-4 mt-xl-0">
            <Row className="ps-xl-5">
              {/* Quick Links */}
              <Col xs={12} sm={4} className=" pe-md-4">
                <h5 className="ff-poppins">Quick Links</h5>
                <ListGroup variant="flush" className="m-0 p-0">
                  {[
                    "Tiffin service near me",
                    "Get help",
                    "Sign up to deliver",
                    "Customer FAQs",
                    "Career",
                    "Rewards",
                    "TiffinStash Blogs",
                    "Press Release",
                  ].map((link, index) => (
                    <ListGroup.Item
                      key={index}
                      className="bg-transparent text-white border-0 ff-poppins mt-3 p-0"
                    >
                      {link}
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Col>

              {/* Delivery Areas */}
              <Col xs={12} sm={8} className=" pe-md-4 mt-4 mt-sm-0">
                <h5 className="ff-poppins">Delivery Areas</h5>
                <Row>
                  {/* Delivery Areas Column 1 */}
                  <Col xs={12} md={6}>
                    <ListGroup variant="flush">
                      {deliveryAreasPart1.map((area, index) => (
                        <ListGroup.Item
                          key={index}
                          className="bg-transparent text-white border-0 ff-poppins mt-3 p-0"
                        >
                          {area}
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  </Col>

                  {/* Delivery Areas Column 2 */}
                  <Col xs={12} md={6}>
                    <ListGroup variant="flush">
                      {deliveryAreasPart2.map((area, index) => (
                        <ListGroup.Item
                          key={index}
                          className="bg-transparent text-white border-0 ff-poppins mt-3 p-0"
                        >
                          {area}
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>

        {/* Footer Wrapper */}
        <Row className=" py-4 rounded">
          {/* App Store Links */}
          <Col xs={12} lg={5} xl={4} className="d-flex flex-column">
            <div className="text-start w-100 d-flex align-items-center gap-3">
              <img
                className="play-apple-btn"
                src={googlePlay}
                alt="Play Store"
              />
              <img className="play-apple-btn" src={appStore} alt="App Store" />
            </div>
            <div className="d-flex align-items-center gap-2 mt-4">
              <p
                className="ff-poppins fw-medium mb-0 text-decoration-underline"
                style={{ fontSize: "14px" }}
              >
                Invite Friends, Get 5% Discount
              </p>
              <img src={arrowRight} alt="" />
            </div>
          </Col>

          {/* Newsletter Signup */}
          <Col xs={12} lg={7} xl={8} className="ps-xl-5 mt-4 mt-md-0">
            <h4 className="ff-poppins fw-semibold fs_22 mb-4 text-white ms-xl-5">
              Newsletter
            </h4>
            <div className="bg-white w-100 newsletter-div ms-xl-5">
              <Form className="d-flex w-100 rounded-2">
                <Form.Control
                  type="email"
                  placeholder="Email Address"
                  className="  ff-poppins"
                  style={{
                    height: "56px",
                    borderRadius: "8px 0px 0px 8px !important",
                  }}
                />
                <Button
                  type="submit"
                  className=" ff-poppins bg-color-orange"
                  style={{
                    height: "56px",
                    width: "30%",
                    borderRadius: "0 8px 8px 0",
                  }}
                >
                  Subscribe
                </Button>
              </Form>
            </div>
          </Col>
        </Row>

        {/* Footer Line and Links */}
        <div className="border-top border-secondary my-4"></div>

        <Row className="d-flex justify-content-between align-items-center">
          <Col xs={12} md={6}>
            <p className="text-white ff-poppins" style={{ fontSize: "14px" }}>
              Terms Of Service | Privacy Policy | Delivery & Refund Policy | ©
              2024 TiffinStash, Inc.
            </p>
          </Col>
          <Col xs={12} md={6} className="">
            <div className="d-flex gap-3 align-items-sm-end flex-column w-100">
              <div className="d-flex gap-3">
                <a
                  href="https://facebook.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="me-2"
                >
                  <img
                    src={facebookIcon}
                    alt="Facebook"
                    style={{ width: "24px", height: "24px" }}
                  />
                </a>
                <a
                  href="https://instagram.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="me-2"
                >
                  <img
                    src={instagramIcon}
                    alt="Instagram"
                    style={{ width: "24px", height: "24px" }}
                  />
                </a>
                <a
                  href="https://youtube.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="me-2"
                >
                  <img
                    src={youtubeIcon}
                    alt="YouTube"
                    style={{ width: "24px", height: "24px" }}
                  />
                </a>
                <a
                  href="https://linkedin.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="me-2"
                >
                  <img
                    src={linkedInIcon}
                    alt="LinkedIn"
                    style={{ width: "24px", height: "24px" }}
                  />
                </a>
                <a
                  href="https://whatsapp.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="me-2"
                >
                  <img
                    src={whatsappIcon}
                    alt="WhatsApp"
                    style={{ width: "24px", height: "24px" }}
                  />
                </a>
              </div>
              <p className="mb-0 ff-poppins text-white fs_14 custom-padding">
                © 2024, Tiffin Stash Inc.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SellerLandingFooter;
