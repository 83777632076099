import React from "react";
import { Container, Row, Col, ListGroup } from "react-bootstrap";
import manage from "../../../assets/images/manage.png";

const SeamlesslyManage = () => {
  return (
    <div style={{ background: "#FFF9F5" }} className="py-5">
      <Container>
        <h4 className="fw-bold text-dark text-center mb-4 ff-poppins">
          Seamlessly manage your business with TiffinStash
        </h4>

        <Row className="align-items-center justify-content-center flex-column-reverse flex-lg-row">
          {/* Left Side */}
          <Col xs={12} lg={6} className="d-flex justify-content-center">
            <div>
              {/* List Items */}
              <ListGroup className="p-0">
                <ListGroup.Item className="border-0 p-0 mb-3 bg-transparent mt-4 mt-lg-0">
                  <div className="d-flex align-items-start ">
                    <div
                      className="me-2 ff-poppins"
                      style={{
                        lineHeight: "1.5",
                      }}
                    >
                      •
                    </div>
                    <div className="ff-poppins color-dark-grey">
                      <strong>One-Stop Shop:</strong> Our seller portal is a 360
                      solution for seamless management of your partnership.
                    </div>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item className="border-0 p-0 mb-3 bg-transparent mt-4">
                  <div className="d-flex align-items-start">
                    <div
                      className="me-2 ff-poppins"
                      style={{
                        lineHeight: "1.5",
                      }}
                    >
                      •
                    </div>
                    <div className="ff-poppins color-dark-grey">
                      <strong>Effortless Management:</strong> Manage listings,
                      track orders, view payouts, and more – all through our
                      user-friendly web and mobile app.
                    </div>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item className="border-0 p-0 bg-transparent mt-4">
                  <div className="d-flex align-items-start">
                    <div
                      className="me-2 ff-poppins"
                      style={{
                        lineHeight: "1.5",
                      }}
                    >
                      •
                    </div>
                    <div className="ff-poppins color-dark-grey">
                      <strong>Streamlined Operations:</strong> Manage On-Request
                      Delivery orders and track your customer’s deliveries—all
                      from one convenient platform.
                    </div>
                  </div>
                </ListGroup.Item>
              </ListGroup>
            </div>
          </Col>

          {/* Right Side */}
          <Col xs={12} lg={6} className="d-flex justify-content-center">
            <img src={manage} alt="Manage" className="img-fluid" />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SeamlesslyManage;
