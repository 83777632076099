import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

// Import images
import tab4Img from "../../../assets/images/tab1.png";
import tab5Img from "../../../assets/images/tab2.png";
import tab6Img from "../../../assets/images/tab3.png";
import tab1Img from "../../../assets/images/tab4.png";
import tab3Img from "../../../assets/images/tab5.png";
import tab2Img from "../../../assets/images/tab6.png";
import tab7Img from "../../../assets/images/tab7.png";
import tab8Img from "../../../assets/images/tab8.png";
import tab9Img from "../../../assets/images/tab9.png";

const ChooseRightService = () => {
  const [key, setKey] = React.useState("tab1");

  const handleTabChange = (tabKey) => {
    setKey(tabKey);
  };

  return (
    <Container className="py-5">
      {/* Title */}
      <h4
        style={{ color: "#3F414A" }}
        className="text-center mb-4 ff-poppins fw-bold fs_24"
      >
        Choose the right service for your business
      </h4>

      {/* Tabs */}
      <div className="mb-4">
        <div className="tabs-div d-flex align-items-center justify-content-between p-2">
          <button
            className={`text-nowrap ${
              key === "tab1" ? "custom-tab-active" : "custom-tab"
            } px-4 border-0 fs_14 ff-poppins`}
            onClick={() => handleTabChange("tab1")}
          >
            TiffinStash Delivery
          </button>
          <button
            className={`text-nowrap ${
              key === "tab2" ? "custom-tab-active" : "custom-tab"
            } px-4 border-0 fs_14 ff-poppins`}
            onClick={() => handleTabChange("tab2")}
          >
            Seller Delivery
          </button>
          <button
            className={`text-nowrap ${
              key === "tab3" ? "custom-tab-active" : "custom-tab"
            } px-4 border-0 fs_14 ff-poppins`}
            onClick={() => handleTabChange("tab3")}
          >
            On-Request Delivery
          </button>
        </div>
      </div>

      {/* Description Line */}
      <p
        className="ff-poppins color-grey fs_14 mt-3 text-center mb-4"
        style={{ fontSize: "16px" }}
      >
        Customers order on our platform, you prepare the tiffin, and we deliver
        it to their doorstep.
      </p>

      {/* Tab Content */}
      {key === "tab1" && (
        <Row className="g-4 text-center">
          <Col md={4}>
            <Card className="border-light shadow-sm h-100 p-4">
              <Card.Body>
                <img
                  src={tab1Img}
                  alt="More Orders"
                  className="img-fluid mb-2"
                  style={{ width: "64px", height: "64px", borderRadius: "8px" }}
                />
                <h5 className="ff-poppins color-orange fw-semibold mt-3">
                  More Orders
                </h5>
                <p className="ff-poppins color-grey fs_14 mt-3">
                  Gain more orders and boost your sales by joining our platform.
                </p>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="border-light shadow-sm h-100 p-4">
              <Card.Body>
                <img
                  src={tab2Img}
                  alt="Wider Reach"
                  className="img-fluid mb-2"
                  style={{ width: "64px", height: "64px", borderRadius: "8px" }}
                />
                <h5 className="ff-poppins color-orange fw-semibold mt-3">
                  Wider Reach
                </h5>
                <p className="ff-poppins color-grey fs_14 mt-3">
                  Grow your business by reaching customers beyond your usual
                  delivery area.
                </p>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="border-light shadow-sm h-100 p-4">
              <Card.Body>
                <img
                  src={tab3Img}
                  alt="Convenience"
                  className="img-fluid mb-2"
                  style={{ width: "64px", height: "64px", borderRadius: "8px" }}
                />
                <h5 className="ff-poppins color-orange fw-semibold mt-3">
                  Convenience
                </h5>
                <p className="ff-poppins color-grey fs_14 mt-3">
                  Easily prepare tiffins while we handle the tiffin delivery and
                  the support.
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}

      {key === "tab2" && (
        <Row className="g-4 text-center">
          <Col md={4}>
            <Card className="border-light shadow-sm h-100 p-4">
              <Card.Body>
                <img
                  src={tab4Img}
                  alt="More Customers"
                  className="img-fluid mb-2"
                  style={{ width: "64px", height: "64px", borderRadius: "8px" }}
                />
                <h5 className="ff-poppins color-orange fw-semibold mt-3">
                  More Customers
                </h5>
                <p className="ff-poppins color-grey fs_14 mt-3">
                  Opportunity to connect with a wider audience and grow your
                  business.
                </p>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="border-light shadow-sm h-100 p-4">
              <Card.Body>
                <img
                  src={tab5Img}
                  alt="Optimise your cost"
                  className="img-fluid mb-2"
                  style={{ width: "64px", height: "64px", borderRadius: "8px" }}
                />
                <h5 className="ff-poppins color-orange fw-semibold mt-3">
                  Optimise your cost
                </h5>
                <p className="ff-poppins color-grey fs_14 mt-3">
                  Reduce delivery costs by streamlining your delivery
                  operations.
                </p>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="border-light shadow-sm h-100 p-4">
              <Card.Body>
                <img
                  src={tab6Img}
                  alt="Flexibility"
                  className="img-fluid mb-2"
                  style={{ width: "64px", height: "64px", borderRadius: "8px" }}
                />
                <h5 className="ff-poppins color-orange fw-semibold mt-3">
                  Flexibility
                </h5>
                <p className="ff-poppins color-grey fs_14 mt-3">
                  Enjoy the flexibility to manage your deliveries according to
                  your preferences.
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}

      {key === "tab3" && (
        <Row className="g-4 text-center">
          <Col md={4}>
            <Card className="border-light shadow-sm h-100 p-4">
              <Card.Body>
                <img
                  src={tab7Img}
                  alt="Tailored Services"
                  className="img-fluid mb-2"
                  style={{ width: "64px", height: "64px", borderRadius: "8px" }}
                />
                <h5 className="ff-poppins color-orange fw-semibold mt-3">
                  More Coverage
                </h5>
                <p className="ff-poppins color-grey fs_14 mt-3">
                  Expand your service area, reach new neighborhoods, and
                  increase your market presence.
                </p>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="border-light shadow-sm h-100 p-4">
              <Card.Body>
                <img
                  src={tab8Img}
                  alt="Efficient Delivery"
                  className="img-fluid mb-2"
                  style={{ width: "64px", height: "64px", borderRadius: "8px" }}
                />
                <h5 className="ff-poppins color-orange fw-semibold mt-3">
                  Cost Effective
                </h5>
                <p className="ff-poppins color-grey fs_14 mt-3">
                  Save on delivery expenses while ensuring efficient tiffin
                  service for your customers.
                </p>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="border-light shadow-sm h-100 p-4">
              <Card.Body>
                <img
                  src={tab9Img}
                  alt="Reliable Support"
                  className="img-fluid mb-2"
                  style={{ width: "64px", height: "64px", borderRadius: "8px" }}
                />
                <h5 className="ff-poppins color-orange fw-semibold mt-3">
                  Reliability
                </h5>
                <p className="ff-poppins color-grey fs_14 mt-3">
                  Trust in our dependable delivery system to ensure that your
                  tiffins reach your customers safely every time.
                </p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default ChooseRightService;
