import logo from "./logo.svg";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "react-phone-input-2/lib/style.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SellerLandingHeader from "./components/sellerLanding/SellerLandingHeader/SellerLandingHeader";
import SellerImageSlider from "./components/sellerLanding/SellerLandingImageSlider/SellerLandingImageSlider";
import ChooseRightService from "./components/sellerLanding/SellerRightChoice/ChooseRightService";
import SeamlesslyManage from "./components/sellerLanding/SeamlessManage/SeamlesslyManage";
import FAQ from "./components/sellerLanding/Faq/FAQ";
import SellerLandingFooter from "./components/sellerLanding/AppFooter/SellerLandingFooter";

function App() {
  return (
    <div>
      <SellerLandingHeader />
      <SellerImageSlider />
      <ChooseRightService />
      <SeamlesslyManage />
      <FAQ />
      <SellerLandingFooter />
    </div>
  );
}

export default App;
